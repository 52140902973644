import {
  Button,
  createToast,
  Form,
  IconButton,
  ModalDialog,
  ModalTrigger,
  StarRating,
} from "@archery-inc/design-system";
import { FormEvent, useState } from "react";
import { TextArea } from "react-aria-components";
import { FormattedMessage, useIntl } from "react-intl";
import { useSendFeedbackForGeneration } from "../api/graphql/messaging";
import { amplitude } from "../api/third-party";

export default function Feedback({
  generationId,
  presetId,
  rating,
}: {
  generationId: string;
  presetId?: string;
  rating?: number;
}) {
  const [updateGenerationFeedback] = useSendFeedbackForGeneration();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleRatingChange = (newRating: number) => {
    amplitude.logEvent("Web:Client:AiReveals:Feedback:StarRating:StarClicked", {
      rating: newRating,
      presetId,
      generationId,
    });
    setModalOpen(true);
    void updateGenerationFeedback({
      variables: { generationId, rating: newRating, message: null },
    });
  };

  return (
    <div className="w-full flex items-center justify-center">
      <div className="bg-white w-fit p-2 rounded-2 shadow-y-plus-1">
        <StarRating
          rating={rating}
          onRatingChange={(newRating) => void handleRatingChange(newRating)}
          frozen={!!rating}
        />
        <ModalTrigger onOpenChange={setModalOpen} isOpen={isModalOpen}>
          {/* TODO: this is to prevent a warning, we shouldn't use the modeltrigger if the modal is controlled */}
          <Button className="hidden" />
          {rating && (
            <FeedbackForm
              generationId={generationId}
              rating={rating}
              presetId={presetId}
            />
          )}
        </ModalTrigger>
      </div>
    </div>
  );
}

function FeedbackForm({
  generationId,
  presetId,
  rating,
}: {
  generationId: string;
  presetId?: string;
  rating: number;
}) {
  const intl = useIntl();
  const [updateGenerationFeedback, { loading }] =
    useSendFeedbackForGeneration();

  const handleSubmit = (e: FormEvent<HTMLFormElement>, close: () => void) => {
    e.preventDefault();
    const feedback = new FormData(e.currentTarget).get("content") as string;
    amplitude.logEvent("Web:Client:AiReveals:Feedback:Form:Submited", {
      presetId,
      generationId,
      rating,
    });
    void updateGenerationFeedback({
      variables: { generationId, message: feedback || null, rating },
    }).then(() => {
      close();
      createToast(
        <p>
          <FormattedMessage id="ai_reveal_feedback_thanks_toast_msg" />
        </p>,
      );
    });
  };

  function getPlaceholder() {
    switch (rating) {
      case 1:
        return intl.formatMessage({ id: "ai_reveal_feedback_msg_one" });
      case 2:
        return intl.formatMessage({ id: "ai_reveal_feedback_msg_two" });
      case 3:
        return intl.formatMessage({ id: "ai_reveal_feedback_msg_three" });
      case 4:
        return intl.formatMessage({ id: "ai_reveal_feedback_msg_four" });
      case 5:
        return intl.formatMessage({ id: "ai_reveal_feedback_msg_five" });
      default:
        return intl.formatMessage({ id: "ai_reveal_feedback_msg_three" });
    }
  }

  return (
    <ModalDialog
      className="flex flex-col gap-3 p-5 relative"
      containerClassName="w-full max-w-md"
    >
      {({ close }) => (
        <Form onSubmit={(e) => handleSubmit(e, close)}>
          <IconButton
            onPress={close}
            icon="close"
            color="grey"
            className="absolute top-4 right-4"
          />
          <StarRating rating={rating} frozen />
          <div>
            <TextArea
              autoFocus
              className="border-1 border-black rounded-2 p-2 w-full mt-2"
              name="content"
              maxLength={Infinity}
              placeholder={getPlaceholder()}
            />
          </div>
          <div className="flex gap-2 mt-2 justify-center items-center">
            <Button onPress={close} color="grey">
              <FormattedMessage id="common_cancel" />
            </Button>
            <Button
              type="submit"
              color="black"
              leftIcon={loading ? "spinner" : undefined}
              isDisabled={loading}
            >
              <FormattedMessage id="send_feedback_txt" />
            </Button>
          </div>
        </Form>
      )}
    </ModalDialog>
  );
}
