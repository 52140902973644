import { Button, Icon, IconButton } from "@archery-inc/design-system";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { AiAnimatedElementsGeneration } from "../__generated__/graphql";
import { useGenerationQuery } from "../api/graphql/generation";
import Feedback from "../common/Feedback";
import { useMainStore } from "../common/store";
import { downloadGeneration } from "./CreatePage/store/aiStore";
import { useAspectRatioString } from "./CreatePage/store/selectors";

export default function GenerationPage() {
  const { generationId } = useParams();
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const { data } = useGenerationQuery({ generationId: generationId! });
  const aspectRatio = useAspectRatioString();
  const generation =
    data?.getAiAnimatedElementsGeneration as AiAnimatedElementsGeneration;
  const preset = generation?.presets?.[0];
  const videoUrls = preset?.task?.parsedResult as string[];
  const toggleMute = useMainStore((state) => state.toggleMute);
  const isMuted = useMainStore((state) => state.isMuted);

  return (
    <div className="absolute z-30 sm:p-20 top-0 left-0 w-screen h-screen flex items-center justify-center bg-black/75">
      <IconButton
        icon="close"
        color="dark"
        className="size-10 absolute top-8 right-8"
        onPress={() => navigate("/my-generations")}
      />
      {data ? (
        <div
          className="max-h-[calc(100vh-160px)] relative"
          style={{ aspectRatio }}
        >
          <video
            className="rounded-4"
            autoPlay
            playsInline
            preload="auto"
            muted={isMuted}
            loop
            onLoadedMetadata={() => setIsLoaded(true)}
            src={videoUrls[0]}
          />
          {isLoaded && (
            <div className="bottom-3 right-3 absolute flex gap-3 items-center">
              <Feedback
                generationId={generation.generationId}
                presetId={generation.presets[0].presetId}
                rating={generation?.feedback?.rating}
              />
              <Button
                leftIcon="download"
                color="black"
                onPress={() => downloadGeneration(generation)}
              >
                <FormattedMessage id="share_video_download" />
              </Button>
              <div>
                <IconButton
                  icon={isMuted ? "sound_off" : "sound"}
                  variant="round"
                  color="dark"
                  onPress={toggleMute}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="bg-white rounded-4 size-60 flex items-center justify-center">
          <Icon icon="spinner" className="bg-black size-6" />
        </div>
      )}
    </div>
  );
}
